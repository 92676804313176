// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import WeatherIntegration from "../../blocks/weatherintegration/src/WeatherIntegration";
import LocationFinder from "../../blocks/weatherintegration/src/LocationFinder";
import WeatherDetails from "../../blocks/weatherintegration/src/WeatherDetails";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import CommunityForum from "../../blocks/communityforum/src/CommunityForum";
import VideoLibrary from "../../blocks/videolibrary/src/VideoLibrary";
import AssessmentTest from "../../blocks/assessmenttest/src/AssessmentTest";
import LoyaltySystem from "../../blocks/loyaltysystem/src/LoyaltySystem";
import Download from "../../blocks/download/src/Download";
import DownloadUpload from "../../blocks/download/src/DownloadUpload";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import Reservations from "../../blocks/reservations/src/Reservations";


const routeMap = {
WeatherIntegration:{
 component:WeatherIntegration,
path:"/WeatherIntegration"},
LocationFinder:{
 component:LocationFinder,
path:"/LocationFinder"},
WeatherDetails:{
 component:WeatherDetails,
path:"/WeatherDetails"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
CommunityForum:{
 component:CommunityForum,
path:"/CommunityForum"},
VideoLibrary:{
 component:VideoLibrary,
path:"/VideoLibrary"},
AssessmentTest:{
 component:AssessmentTest,
path:"/AssessmentTest"},
LoyaltySystem:{
 component:LoyaltySystem,
path:"/LoyaltySystem"},
Download:{
 component:Download,
path:"/Download"},
DownloadUpload:{
 component:DownloadUpload,
path:"/DownloadUpload"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
Reservations:{
 component:Reservations,
path:"/Reservations"},
GroupVideoCall:{
 component:GroupVideoCall,
path:"/GroupVideoCall"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;